.equal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.equal > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .signupTitle {
    font-size: 1.5rem;
  }
}

.navLinkWithoutCursor {
  cursor: default;
}

@media (max-width: 768px) {
  .navResponsive .nav-item {
    display: none;
  }
}
