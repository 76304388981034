.msAuto {
  margin-left: auto !important;
}

.lh1 {
  line-height: 1 !important;
}

.subheader {
  font-size: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  line-height: 1.6;
  color: #656d77;
}

.subheaderBig {
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.04em;
  line-height: 1.6;
  color: #656d77;
}

.minSizeChartLoading {
  min-height: 8rem;
}

.meAuto {
  margin-right: auto !important;
}

.dropdownMenuChart {
  position: absolute;
  cursor: pointer;
  top: 100%;
  z-index: 1000;
  display: none;
  min-width: 11rem;
  padding: 0.25rem 0;
  margin: 0;
  font-size: 0.875rem;
  color: #232e3c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(101, 109, 119, 0.16);
  border-radius: 4px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 !important;
}

.dropdownItemChart {
  min-width: 11rem;
  display: flex;
  align-items: center;
  margin: 0;
  line-height: 1.4285714;
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  clear: both;
  font-weight: 400;
  color: inherit;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  text-decoration: none;
}

.dropdownItemChart:focus,
.dropdownItemChart:hover {
  color: inherit;
  text-decoration: none;
  background-color: rgba(101, 109, 119, 0.06);
}

.show {
  display: block;
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate3d(-90px, 17px, 0px);
}

.dropdownItemChart.active,
.dropdownItemChart:active {
  color: #206bc4;
  text-decoration: none;
  background-color: rgba(32, 107, 196, 0.06);
}

.textGreen {
  color: #2fb344 !important;
}

.textRed {
  color: #d63939 !important;
}

.textYellow {
  color: #f59f00 !important;
}

.variationIcon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
  vertical-align: bottom;
  stroke-width: 1.5;
}

.smallEmptyState {
  margin-top: 0.5rem;
  margin-bottom: 2.2rem;
}

.bigEmptyState {
  margin-top: 0rem;
  margin-bottom: 1rem;
}

.smallTextEmptyState {
  margin-top: 1rem;
  font-size: 12px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-align: justify;
}