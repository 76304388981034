.errorMessage {
  margin-bottom: 0;
  font-style: 'italic';
}

.successMessage {
  margin-bottom: 10px;
}

.button {
  margin-right: 10px;
}
