.highlightText {
  text-decoration: underline;
  font-weight: bold;
}

.italicText {
  font-style: italic;
}

.btnTextLineSpacing {
  line-height: 1.5;
}

.customSwitchIndicator {
  margin-right: 10px;
}

/* Default style for large screens */
.buttonGroup {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

/* Style for small screens */
@media (max-width: 768px) {
  .buttonGroup {
    flex-direction: column;
    align-items: stretch;
  }

  .buttonGroup button:not(:last-child) {
    margin-bottom: 1rem;
  }
}