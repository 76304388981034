.emptyStateDivBox {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  text-align: center;
}

.emptyStateImage {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
  max-width: 300px;
}

.emptyStateText {
  margin-top: 20px;
}

.largeContainer {
  max-width: 1600px;
  margin-bottom: 150px;
}

@media (min-width: 992px) {
  .removePaddingFirstCol {
    padding-left: 0;
  }

  .removePaddingLastCol {
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .removePadding {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .smallButtonOnBigScreen {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    min-width: 1.625rem;
    line-height: 1.33333333;
    border-radius: 3px;
  }
}

.collapse {
  max-height: 0;
  transition: max-height 0.25s ease-out;
  overflow: hidden;
}

.showCollapse {
  max-height: 500px;
  overflow: auto;
  transition: max-height 0.5s ease-in;
}

.dropdownItemBiggerLines {
  line-height: 2.5rem;
}

.editDashboardText {
  display: inline;
}

@media (max-width: 992px) {
  .editDashboardText {
    display: none;
  }
}

.tabletModeDiv {
  opacity: 0;
  max-height: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, max-height 0.3s ease;
  padding: 0rem;
}

.tabletModeDivOpen {
  visibility: visible;
  opacity: 1;
  max-height: 20rem;
}
