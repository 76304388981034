.arrowDown {
  font-size: 35px;
}

.dropZone {
  height: 100%;
  min-height: 10rem;
  border-width: 1px;
  border-radius: 2px;
  padding: 2rem;
  border-color: '#eeeeee';
  border-style: dashed;
  outline: none;
  transition: border 0.24s ease-in-out;
  background-color: #fafafa;
  color: #bdbdbd;
  margin-bottom: 1.5rem;
}

.dropZoneActive {
  background-color: #ecf0f1;
}

.dropZoneDragging {
  opacity: 0.5;
}

@media (min-width: 768px) {
  .plusButton {
    right: 0;
    left: auto;
  }
}


.settings {
  opacity: 0;
  max-height: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, max-height 0.3s ease;
  padding: 0rem
}

.settingsOpen {
  visibility: visible;
  opacity: 1;
  max-height: 1000px;
  padding: 1.5rem 1.5rem;
}

.iconContainer {
  margin-top: 1rem;
  height: 10rem;
  overflow: scroll;
}

.iconDiv {
  padding: 5px;
  width: 35px;
  margin-bottom: 8px;
}

.iconDivChecked {
  background-color: #f5f7fb;
  border-radius: 4px;
}

.iconLabel {
  cursor: pointer;
  margin-bottom: 0;
}

.iconInput {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
