.containerWithMargin {
  margin-top: 3rem;
}


.iconContainer {
  margin-top: 1rem;
  height: 10rem;
  overflow: scroll;
}

.iconDiv {
  padding: 5px;
  width: 35px;
  margin-bottom: 8px;
}

.iconDivChecked {
  background-color: #f5f7fb;
  border-radius: 4px;
}

.iconLabel {
  cursor: pointer;
  margin-bottom: 0;
}

.iconInput {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
