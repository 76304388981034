.userIconImage {
  border-radius: 50%;
  border: solid;
  border-color: #5eba00;
}

.houseIconImage {
}

.containerWithMargin {
  margin-top: 3rem;
}
