.scrollTopGuide {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  right: 0;
  height: 20%;
  width: 100%;
}

.scrollBottomGuide {
  position: fixed;
  z-index: 1050;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%;
  width: 100%;
}

.deviceListDragAndDrop {
  cursor: 'pointer';
  user-select: 'none';
}

.deviceListDragAndDropDragging {
  opacity: 0.5;
}

.deviceListDragAndDropActive {
  background-color: #ecf0f1;
}

.deviceListDragAndDropEmpty {
  border: 1px solid #e90a0a;
}

.deviceListDragAndDropEmptyAutoGenerated {
  border: 1px dashed #e90a0a;
}

.deviceListDragAndDropEmpty:focus {
  border: 1px solid #e90a0a;
  box-shadow: 0 0 5px #e90a0a;
}

.deviceListRemoveButton {
  z-index: 0;
}