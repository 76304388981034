.tdCenter {
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.textAlignMiddleContainer {
  display: table;
}

.textAlignMiddle {
  display: table-cell;
  vertical-align: middle;
}

.greenIcon {
  color: #5eba00;;
  font-size: 24px;
}

.redIcon {
  color: #cd201f;
  font-size: 24px;
}

.line {
  color: #555;
  background-color: #555;
  border-color: #555;
  height: 1px;
  width: 40px;
}
